import React from 'react';
import btcImg from '../../../assets/images/btc.jpg';
import bankImg from '../../../assets/images/bank.png';

interface WithdrawFundMobileProps {
	openModal: VoidFunction;
	openBankModal: VoidFunction;
}

const WithdrawFundMobile: React.FC<WithdrawFundMobileProps> = ({
	openModal,
	openBankModal,
}) => {
	return (
		<div className='bv-withdraw-fund-mobile py-3'>
			<div className='container'>
				<div className='card'>
					<div className='card-body'>
						<center>
							<h2> Withdrawal Methods </h2>
							{/* <a
								className='btn btn-dark mb-3'
								style={{ width: '100%' }}
								onClick={openBankModal}
							>
								<img
									src={bankImg}
									style={{ height: 50 }}
									alt='Withdrawal Methods (Bank)'
								/>
							</a> */}

							<a className='btn btn-dark' onClick={openModal}>
								<img src={btcImg} alt='Withdrawal Methods' />
							</a>
						</center>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WithdrawFundMobile;
