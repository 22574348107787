import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AllRouteConstants } from '../../../router/routes';
import { useForm } from '../../../hooks/useForm';
import { RegisterInput } from '../../../interfaces/Auth';
import { passwordValidator } from '../../../utils/validators/passwordValidator';
import { emailValidator } from '../../../utils/validators/emailValidator';
import { emptyValidator } from '../../../utils/validators/emptyValidator';
import AuthInputField from '../components/AuthInputField';
import useApi from '../../../hooks/useApi';
import authService from '../../../services/AuthService';
import { useAuthActions } from '../../../hooks/useReduxActions';
import { phoneValidator } from '../../../utils/validators/phoneValidator';

const register = (data: RegisterInput) => authService.register(data);

export const Register: React.FC = () => {
	const navigate = useNavigate();

	const [termsAccepted, setTermsAccepted] = useState(false);
	const toggleAcceptTerms = () => setTermsAccepted(!termsAccepted);

	const registerForm = useForm<RegisterInput>(
		{
			confirmPassword: '',
			email: '',
			firstname: '',
			lastname: '',
			password: '',
			phone: '',
		},
		{
			password: passwordValidator,
			email: emailValidator,
			firstname: emptyValidator,
			lastname: emptyValidator,
			confirmPassword: emptyValidator,
			phone: phoneValidator,
		}
	);

	const registerApiRequest = useApi<
		{ status: boolean; message: string; session: string },
		RegisterInput
	>(register);

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		registerForm.onChange(e.target.name as keyof RegisterInput, e.target.value);
	};

	const { toggleSuccessAlert } = useAuthActions();

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (registerApiRequest.loading || !termsAccepted) {
			return;
		}

		registerForm.resetFormErrors();
		registerApiRequest.reset();

		const valid = registerForm.validate();

		if (valid) {
			const payload = registerForm.form;
			if (!payload.phone) payload.phone = undefined;

			const result = await registerApiRequest.request(registerForm.form);

			console.log(result);

			if (result) {
				toggleSuccessAlert('Please check your mailbox and confirm your email!');
				navigate(
					AllRouteConstants.auth.complete_profile + '?session=' + result.session
				);
			}
		}
	};

	return (
		<div className='bv-register-page'>
			<div className='container my-auto py-5'>
				<div className='row g-0'>
					<div className='col-11 col-md-8 col-lg-7 col-xl-6 mx-auto'>
						<p className='text-2 text-light'>
							Already a member?{' '}
							<a
								className='fw-500'
								onClick={() => navigate(AllRouteConstants.auth.login)}
							>
								Login
							</a>
						</p>

						<h3 className='text-white mb-4'>Register Your Account</h3>

						<div className='d-flex'>
							<a
								className='btn btn-primary btn-sm fw-400 rounded-3 shadow-none'
								onClick={() => navigate(AllRouteConstants.main.index)}
							>
								<span className='me-2'>
									<FontAwesomeIcon
										icon={icon({ name: 'home', style: 'solid' })}
									/>
								</span>
								<span className='mx-3'>Back To Homepage</span>
							</a>
						</div>

						<div className='d-flex align-items-center my-4'>
							<hr className='flex-grow-1 bg-dark-4' />
						</div>

						<form
							name='loginForm'
							className='form-dark'
							autoComplete=''
							onSubmit={handleSubmit}
						>
							<div className='mb-3'>
								<label className='form-label text-light'>First Name</label>
								<AuthInputField
									type='text'
									name='firstname'
									value={registerForm.form.firstname}
									onChange={handleChange}
									error={registerForm.formErrors.firstname}
									placeholder='Enter Your First Name'
								/>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light'>Last Name</label>
								<AuthInputField
									type='text'
									error={registerForm.formErrors.lastname}
									name='lastname'
									value={registerForm.form.lastname}
									onChange={handleChange}
									placeholder='Enter Your Last Name'
								/>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light'>Email</label>
								<AuthInputField
									type='text'
									error={registerForm.formErrors.email}
									name='email'
									value={registerForm.form.email}
									onChange={handleChange}
									placeholder='Enter Your Email'
								/>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light'>Phone</label>
								<AuthInputField
									type='text'
									error={registerForm.formErrors.phone || ''}
									name='phone'
									value={registerForm.form.phone || ''}
									onChange={handleChange}
									placeholder='Enter Your Phone Number'
								/>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light'>Referral code</label>
								<input
									type='text'
									className='form-control'
									name='reference'
									// value=''
									placeholder='Referral code'
								/>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light loginPassword'>
									Password
								</label>

								<AuthInputField
									type='password'
									name='password'
									error={registerForm.formErrors.password}
									value={registerForm.form.password}
									onChange={handleChange}
									placeholder='Enter Password'
								/>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light loginPassword'>
									Confirm Password
								</label>

								<AuthInputField
									type='password'
									name='confirmPassword'
									error={registerForm.formErrors.confirmPassword}
									value={registerForm.form.confirmPassword}
									onChange={handleChange}
									placeholder='Confirm Password'
								/>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light loginPassword'>
									I accept the{' '}
									<Link
										to={AllRouteConstants.main.terms_of_use}
										target={'_blank'}
										className='link'
									>
										&nbsp;Terms &amp; Conditions&nbsp;
									</Link>{' '}
									of Cosmic Trade
								</label>{' '}
								<input
									type='checkbox'
									checked={termsAccepted}
									onClick={toggleAcceptTerms}
									id='cb1'
									name='check'
									onChange={() => {}}
								/>
							</div>

							{registerApiRequest.error && (
								<div className='mt-3 api-error alert alert-danger text-center'>
									{registerApiRequest.error}
								</div>
							)}

							<button
								className='btn btn-primary my-2'
								name='submit'
								disabled={registerApiRequest.loading}
								type='submit'
							>
								Sign Up
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
