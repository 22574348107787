import React, { useState } from 'react';
import KrLeftSide from '../../../components/KrLeftside';
import KrManagerNav from '../../../components/KrManagerNav';
import BtcDeposit from '../components/BtcDeposit';
import DepositFundMobile from '../components/DepositFundMobile';
import btcImg from '../../../assets/images/btc.jpg';
import ethImg from '../../../assets/images/ethImg.jpeg';
import creditCardImg from '../../../assets/images/creditCard.jpeg';
import EthDeposit from '../components/EthDeposit';
import CardDeposit from '../components/CardDeposit';

export const DepositFund: React.FC = () => {
	const [isBtcDepositOpen, setIsBtcDepositOpen] = useState(false);
	const [isEthDepositOpen, setIsEthDepositOpen] = useState(false);
	const [isCardDepositOpen, setCardDepositOpen] = useState(false);

	return (
		<>
			{isBtcDepositOpen && (
				<BtcDeposit
					isOpen={isBtcDepositOpen}
					onClose={() => setIsBtcDepositOpen(false)}
				/>
			)}

			{isEthDepositOpen && (
				<EthDeposit
					isOpen={isEthDepositOpen}
					onClose={() => setIsEthDepositOpen(false)}
				/>
			)}

			{isCardDepositOpen && (
				<CardDeposit
					isOpen={isCardDepositOpen}
					onClose={() => setCardDepositOpen(false)}
				/>
			)}

			<div className='bv-deposit-fund-page'>
				<KrLeftSide />

				<section className='kr-dashboard'>
					<KrManagerNav />

					<div className='kr-manager-filter'>
						<button
							type='button'
							className='btn btn-autowidth btn-red btn-large'
							name='button'
						>
							Select Payment Gateways
						</button>
					</div>

					<div className='kr-admin-table'>
						<div className='kr-admin-line kr-admin-line-cls'>
							<div className='kr-admin-field'>
								<div>
									<label>Select payment method</label>
								</div>
								<div></div>
							</div>

							<section className='mb-5'>
								{/* <div className='payment-block mb-3 p-4 bg-light'>
									<div>
										<a className='ab' onClick={() => setCardDepositOpen(true)}>
											<label
												className='form-check-label'
												htmlFor='exampleRadio1'
											>
												<img
													style={{ height: 100, objectFit: 'cover' }}
													src={creditCardImg}
													alt='p'
												/>
											</label>
										</a>
									</div>
								</div> */}

								<div className='payment-block mb-3 p-4 bg-light'>
									<div>
										<a className='ab' onClick={() => setIsBtcDepositOpen(true)}>
											<label
												className='form-check-label'
												htmlFor='exampleRadio1'
											>
												<img style={{ height: 30 }} src={btcImg} alt='p' />
											</label>
										</a>
									</div>
								</div>

								<div className='payment-block mb-3 p-4 bg-light'>
									<div>
										<a className='ab' onClick={() => setIsEthDepositOpen(true)}>
											<label
												className='form-check-label'
												htmlFor='exampleRadio1'
											>
												<img
													style={{ height: 100, objectFit: 'cover' }}
													src={ethImg}
													alt='p'
												/>
											</label>
										</a>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>

			<DepositFundMobile
				openModal={() => setIsBtcDepositOpen(true)}
				openEthModal={() => setIsEthDepositOpen(true)}
				openCardModal={() => setCardDepositOpen(true)}
			/>
		</>
	);
};
