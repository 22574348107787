import { FC, useMemo, useState } from 'react';
import { TradingPlan } from '../../../../interfaces/User';
import PopModal from '../../../../components/ModelLayout/ModalLayout';
import './styles.scss';

export type TPlanAmountBoxProps = {
	plan: TradingPlan;
	onOk: (val: number) => void;
	onCancel: VoidFunction;
};

const PlanAmountBox: FC<TPlanAmountBoxProps> = (props) => {
	const { plan, onCancel, onOk } = props;

	const [usdAmount, setUsdAmount] = useState<number | null>(null);

	const min = useMemo(() => {
		switch (plan) {
			case 'Sapphire-1000':
				return 1000;

			case 'Gold-10000':
				return 10000;

			case 'Platinum-50000':
				return 50000;

			case 'Diamond-100000':
				return 100000;

			default:
				return undefined;
		}
	}, [plan]);

	const max = useMemo(() => {
		switch (plan) {
			case 'Sapphire-1000':
				return 9999;

			case 'Gold-10000':
				return 49999;

			case 'Platinum-50000':
				return 99999;

			default:
				return undefined;
		}
	}, [plan]);

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
		if (!usdAmount) return;
		onOk(usdAmount);
	};

	return (
		<PopModal modalToggler={true} onClose={() => {}}>
			<div className='bv-plan-amount-modal'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h4 className='modal-title'>
							<strong>Plan</strong>
						</h4>
						<button type='button' className='close' onClick={onCancel}>
							×
						</button>
					</div>

					<form autoComplete='off' onSubmit={handleSubmit}>
						<div className='modal-body'>
							<div className='form-group'>
								<div className='input-group'>
									<div className='input-group'>
										<input
											type='number'
											name='amount'
											className='form-control input-lg'
											id='amount'
											min={min}
											max={max}
											value={usdAmount || ''}
											onChange={(e) => setUsdAmount(e.target.valueAsNumber)}
											placeholder='Enter Amount'
											required
										/>
										<div className='input-group-prepend'>
											<span className='input-group-text'>USD</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='modal-footer'>
							<button type='submit' className='btn btn-primary'>
								Submit
							</button>

							<button
								type='button'
								className='btn btn-default'
								onClick={onCancel}
							>
								Cancel
							</button>
						</div>
					</form>
				</div>
			</div>
		</PopModal>
	);
};

export default PlanAmountBox;
