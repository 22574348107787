import { TradeInput } from '../../../interfaces/trading';

interface KrDashPanCryPops {
	form: Omit<TradeInput, 'trade_mode'>;
	handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
	buyAmount?: string;
	sellAmount?: string;
	loading: boolean;
	handleSubmit: (type: 'Buy' | 'Sell') => Promise<void>;
}

const KrDashPanCry: React.FC<KrDashPanCryPops> = ({
	form,
	handleChange,
	buyAmount,
	sellAmount,
	loading,
	handleSubmit,
}) => {
	return (
		<div className='kr-dash-pan-cry'>
			<div className='kr-dash-pan-action kr-dash-pan-action-limit'>
				<div className='kr-dash-pan-action-qtd'></div>

				<div className='kr-dash-pan-action-amount'>
					<input
						type='number'
						id='amount_trade'
						className='select-css'
						value={form.amount}
						onChange={handleChange}
						name='amount'
						placeholder='Input Trade Amount'
						min={1}
						step={120}
					/>
				</div>

				<br />

				{/** Trading Type */}
				<div className='kr-dash-pan-action-qtd' kr-market-multticker='48641.33'>
					<select
						name='trading_type'
						id='category'
						className='select-css'
						value={form.trading_type}
						onChange={handleChange}
					>
						<option value='' selected disabled>
							Select
						</option>
						<option value='Cryptocurrency'>Cryptocurrency </option>
						<option value='Currencies'>Currencies</option>
					</select>
					<span></span>
				</div>

				{/** Symbol */}
				<div className='kr-dash-pan-action-qtd' kr-market-multticker='48641.33'>
					<select
						name='symbol'
						id='showsymbols'
						className='select-css'
						value={form.symbol}
						onChange={handleChange}
					>
						<option value='' selected disabled>
							Select
						</option>
						{form.trading_type == 'Cryptocurrency' && (
							<option value='BCHBTC'>BCH/BTC</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='BCHEUR'>BCH/EUR</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='BCHGBP'>BCH/GBP</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='BTCEUR'>BTC/EUR</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='BTCGBP'>BTC/GBP</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='BTCUSD'>BTC/USD</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='EOSBTC'>EOS/BTC</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='EOSEUR'>EOS/EUR</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='EOSUSD'>EOS/USD</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='ETCBTC'>ETC/BTC</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='ETCEUR'>ETC/EUR</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='ETCGBP'>ETC/GBP</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='ETCUSD'>ETC/USD</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='ETHBTC'>ETH/BTC</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='ETHEUR'>ETH/EUR</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='ETHGBP'>ETH/GBP</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='ETHUSD'>ETH/USD</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='ETHUSDT'>ETH/USDT</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='LTCBTC'>LTC/BTC</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='LTCEUR'>LTC/EUR</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='LTCGBP'>LTC/GBP</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='LTCUSD'>LTC/USD</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='LTCUSDT'>LTC/USDT</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='MKRBTC'>MKR/BTC</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='PCPBTC'>PCP-BTC</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='REPBTC'>REP/BTC</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='REPUSD'>REP/USD</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='SHIBUSDT'>SHIB/USDT</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='TRXUSDT'>TRX/USDT</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='TXRGBP'>TXR/GBP</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='XLMBTC'>XLM/BTC</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='XLMEUR'>XLM/EUR</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='XLMUSD'>XLM/USD</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='XRPBTC'>XRP/BTC</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='XRPEUR'>XRP/EUR</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='XRPUSD'>XRP/USD</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='ZECBTC'>ZEC/BTC</option>
						)}
						{form.trading_type == 'Cryptocurrency' && (
							<option value='ZRXBTC'>ZRX/BTC</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='ZRXEUR'>ZRX/EUR</option>
						)}
						{form.trading_type == 'Currencies' && (
							<option value='ZRXUSD'>ZRX/USD</option>
						)}
					</select>
				</div>

				<br />

				{/** Strike rate */}
				<div className='kr-dash-pan-action-qtd' kr-market-multticker='48641.33'>
					<select
						name='strike_rate'
						className='select-css'
						id='strikerateused'
						value={form.strike_rate}
						onChange={handleChange}
					>
						<option value='' selected disabled>
							Select
						</option>
						<option value='75% & 3%'>75% &amp; 3%</option>
						<option value='12% & 5%'>12% &amp; 5%</option>
						<option value={'11% & 13%'}>11% &amp; 13%</option>
						<option value='1% & 18%'>1% &amp; 18%</option>
						<option value='1% & 61%'>1% &amp; 61%</option>
					</select>
					<span></span>
				</div>

				{/** Duration */}
				<div className='kr-dash-pan-action-qtd' kr-market-multticker='48641.33'>
					<select
						name='duration'
						id='interval'
						className='select-css'
						value={form.duration}
						onChange={handleChange}
					>
						<option value='' selected disabled>
							Select
						</option>
						<option value='60'>1 min</option>
						<option value='180'>3 mins</option>
						<option value='300'>5 mins</option>
						<option value='900'>15 mins</option>
						<option value='1800'>30 mins</option>
						<option value='3600'>1 hr</option>
						<option value='7200'>2 hr</option>
						<option value='86400'>1 day</option>
					</select>
				</div>

				<div className='mb-5'></div>

				{buyAmount && (
					<span className='text-success' id='buyamount'>
						${buyAmount}
					</span>
				)}

				<div className='kr-dash-pan-action-btn kr-dash-pan-action-btn-buy'>
					<button
						type='button'
						id='buy'
						className='mtbts'
						disabled={loading}
						onClick={() => handleSubmit('Buy')}
					>
						<img
							className='kr-dash-pan-action-btn-img-b'
							kr-cipc='61b925663163f'
							src='https://oanda-markets.com/factor/buy_market.svg'
							alt=''
						/>

						<span>Buy</span>

						{form.strike_rate && (
							<div className='span2' id='buypercent'>
								{form.strike_rate.split(' ')[0]}
							</div>
						)}
					</button>
				</div>

				<br />

				{sellAmount && (
					<span className='text-danger' id='sellamount'>
						${sellAmount}
					</span>
				)}

				<div className='kr-dash-pan-action-btn kr-dash-pan-action-btn-sell'>
					<button
						type='submit'
						id='sell'
						className='mtbts'
						disabled={loading}
						onClick={() => handleSubmit('Sell')}
					>
						<img
							className='kr-dash-pan-action-btn-img-s'
							kr-cipc='61b925663163f'
							src='https://oanda-markets.com/factor/sell_market.svg'
							alt=''
						/>

						<span>Sell</span>

						{form.strike_rate && (
							<div className='span2' id='sellpercent'>
								{form.strike_rate.split(' ')[2]}
							</div>
						)}
					</button>
				</div>
			</div>
		</div>
	);
};

export default KrDashPanCry;
